import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DeviceDetectorService {
  isMobile(): boolean {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/.test(userAgent);
  }

  isTablet(): boolean {
    const userAgent = window.navigator.userAgent.toLowerCase();
    const isTabletUserAgent = /ipad|tablet|playbook|silk|android(?!.*mobi)/.test(userAgent);
    const isTabletScreenSize = window.innerWidth >= 600 && window.innerWidth <= 1024;
    return isTabletUserAgent || isTabletScreenSize;
  }
}