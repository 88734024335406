export const CARDS_API = {
  url: 'https://vantage-dev.azure-api.net/vantage/boards/v4/',
  key: 'e583acd16f97406e97de9bae1f877f34',
};

export const ASSISTANT_API = {
  url: 'https://vantage-dev.azure-api.net/oai/assistant/v4',
};

export const DATA_API = {
  url: 'https://vantage-dev.azure-api.net/data/v1/',
  key: 'e6b62958232f438d80c282507562210d',
};

export const DATA_API_V4 = {
  url: 'https://vantage-dev.azure-api.net/data/v4/',
};

export const CONFIG_API = {
  url: 'https://vantage-dev.azure-api.net/vantage/config/v1/',
};

export const PROFILES_API = {
  url: 'https://vantage-dev.azure-api.net/vantage/profiles/v1/',
};

export const MATCHING_API = {
  url: 'https://vantage-dev.azure-api.net/matching-engine/v1/',
};

export const SI_STAGING_API = {
  url: 'https://vantage-dev.azure-api.net/staging/v1/',
};

export const SI_DATA_UPLOAD_API = {
  url: 'https://vantage-dev.azure-api.net/si/storage/v1/',
};

export const APIS = [
  CARDS_API,
  DATA_API,
  DATA_API_V4,
  CONFIG_API,
  PROFILES_API,
  SI_STAGING_API,
  SI_DATA_UPLOAD_API,
  MATCHING_API,
  ASSISTANT_API
];

export const aad_access_scopes = [
  'https://VantageAadB2cDev.onmicrosoft.com/card-service/user_impersonation',
];

export const aad_profiles_scopes = [
  'https://VantageAadB2cDev.onmicrosoft.com/profiles/user_impersonation',
];

export const aad_si_staging_scope = [
  'https://VantageAadB2cDev.onmicrosoft.com/staging/user_impersonation',
];

export const aad_si_dataupload_scope = [
  'https://VantageAadB2cDev.onmicrosoft.com/data-upload/user_impersonation',
];

const resourceArray: [string, string[]][] = [
  [CARDS_API.url, aad_access_scopes],
  [ASSISTANT_API.url, aad_access_scopes],
  [DATA_API.url, aad_access_scopes],
  [DATA_API_V4.url, aad_access_scopes],
  [CONFIG_API.url, aad_access_scopes],
  [MATCHING_API.url, aad_access_scopes],
  [PROFILES_API.url, aad_profiles_scopes],
  [SI_STAGING_API.url, aad_si_staging_scope],
  [SI_DATA_UPLOAD_API.url, aad_si_dataupload_scope],
];

export const environment = {
  production: true,
  title: 'Vantage (DEV)',
  includeProfiles: true,
  aad_config: {
    clientId: 'ad9581d9-acdb-4c10-aa73-2093f418f39c',
    authorityDomain: 'https://vantageaadb2cdev.b2clogin.com',
    authority:
      'https://vantageaadb2cdev.b2clogin.com/tfp/vantageaadb2cdev.onmicrosoft.com/B2C_1_SI',
    redirectUri: 'https://web-dev.govantage.io/login',
    postLogoutRedirectUri: 'https://web-dev.govantage.io/login',
    password_authority:
      'https://vantageaadb2cdev.b2clogin.com/tfp/vantageaadb2cdev.onmicrosoft.com/b2c_1_pwreset/',
    access_scopes: aad_access_scopes,
    policies: {
      signUpSignIn: 'B2C_1_SI' as const,
      resetPassword: 'b2c_1_pwreset' as const,
    },
  },
  share_config: {
    share_screenshot_api:
      'https://vantage-dev.azure-api.net/frontend-export/v1/getBoardJpg',
    auth: {
      clientId: '822c4fbf-e60e-45a8-98c0-f88197f2643d',
      authority:
        'https://login.microsoftonline.com/96332844-4275-435a-8218-841a471e729f/',
      redirectUri: 'https://web-dev.govantage.io',
      postLogoutRedirectUri: 'https://web-dev.govantage.io',
      navigateToLoginRequestUrl: false,
      validateAuthority: false,
    },
    cache: {
      cacheLocation: 'localStorage',
    },
    msalScopes: {
      scopes: ['profile'],
    },
  },
  api: CARDS_API,
  data: DATA_API,
  dataV4: DATA_API_V4,
  config: CONFIG_API,
  matching: MATCHING_API,
  profiles: PROFILES_API,
  staging: SI_STAGING_API,
  dataUpload: SI_DATA_UPLOAD_API,
  submissionInstanceID: 1,
  paginationDefault: {
    focus: 10,
  },
  apps: {
    invision: 'https://invis.io/WBUC7K18UQC',
  },
  bot: {
    key: 'f5556510ecd743dc8d8dbea87d984372',
    infoUrl: 'https://vantage-dev.azure-api.net/bots/v1/',
  },
  covidSettings: {
    host: 'https://covid-maps-dev.govantage.io/',
    apiBaseUrl: 'https://vantage-dev.azure-api.net/vantage/covid19/v1/',
    ocpApimSubscriptionKey: '01ee4e0bcdd148a0a0d50ba822816714',
    dataExpirationTime: 900000,
    rootLevelCode: 'WROO'
  },
  mapbox: {
    accessToken:
      'pk.eyJ1IjoiYnJ2YW50YWdlIiwiYSI6ImNrbzhqbzh4czBrdWgydm11dWtqcDF0MjEifQ.NHH18FfjaOCpPRy9Jlkmiw',
    style: 'mapbox://styles/brvantage/cko18usw50myc17pjjy02d20c',
    baseUrl: 'https://api.mapbox.com',
    allowedUrls: [],
    username: 'brvantage'
  },
  azureInsights: {
    instrumentationKey: '27384b81-3dda-439d-bd8f-0bcaf2d0c74a',
  },
};
