import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ASSISTANT_API, environment } from 'apps/vantage-focus/src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GaiAssistantService {
  _apimKey: string;

  set apimkey(value: string) {
    this._apimKey = value;
  }

  constructor(private http: HttpClient) { }

  postMessage(instanceId: number, usedId: number, message: string) {
    const url = `${ASSISTANT_API.url}/messages/queue?instance-id=${instanceId}&unique-identifier=${usedId}&message=${message}`
    const body = {
      message
    };

    return this.http.post(url, body, {
      headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Ocp-Apim-Subscription-Key': this._apimKey
      }
    });
  }

  getMessage(instanceId: number, usedId: number, taskId: number) {
    return this.http.get(
      `${ASSISTANT_API.url}/messages/queue?instance-id=${instanceId}&unique-identifier=${usedId}&task-id=${taskId}`,
      {
        headers: {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          'Ocp-Apim-Subscription-Key': this._apimKey
        }
      }
    );
  }

  checkAssistantAvailability(instanceId) {
    // 31 config type ai bot availability check
    const url = `instanceconfiguration/${instanceId}:31`;
    return this.http.get(`${environment.config.url}${url}`);
  }
}
