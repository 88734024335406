<div
  *ngIf="auth.isLoggedIn && ($isLoaded | async)"
  [ngClass]="{ 'scrollable-page-content': isMobilLandscape }"
>
  <div
    class="main-wrapper {{ cardMode }}"
    [class.main-wrapper__mobile-landscape]="isMobilLandscape"
  >
    <div class="titles" [@headerAnimation]>
      <h1>See what’s next, to make now right.</h1>
      <h2>
        Want to use the next hour better? Get real-time, connected
        <br class="d-none d-sm-block" />
        healthcare insights – and focus on what you do best.
      </h2>
    </div>

    <ng-container *ngIf="isMobile && !isMobilLandscape">
      <ng-container *ngIf="$apps | async as apps">
        <div class="swiper-wrapper">
          <swiper-container #swiper [config]="swiperConfig" height="400px" [pagination]="{ clickable: true }">
            <swiper-slide *ngFor="let app of apps; let i = index">
              <ng-template
                *ngTemplateOutlet="
                  cardTpl;
                  context: { app: app, mode: cardMode }
                "
                swiperSlide
              ></ng-template>
            </swiper-slide>
          </swiper-container>
        </div>
      </ng-container>
    </ng-container>

    <div *ngIf="isMobilLandscape" class="tray-mobile-landscape">
      <ng-container *ngFor="let app of $apps | async; let i = index">
        <ng-container
          *ngTemplateOutlet="
            cardTpl;
            context: {
              app: app,
              mode: cardMode,
              leftMargin: i === 0 ? '64px' : '0px'
            }
          "
        ></ng-container>
      </ng-container>
    </div>

    <perfect-scrollbar *ngIf="!isMobile">
        <div
          class="tray"
          [@listAnimation]="($apps | async).length"
        > 
        <ng-container *ngFor="let app of $apps | async">
          <ng-container
            *ngTemplateOutlet="cardTpl; context: { app: app, mode: cardMode }"
          ></ng-container>
        </ng-container>
      </div>
      </perfect-scrollbar>
  </div>
</div>

<ng-template #cardTpl let-app="app" let-mode="mode" let-leftMargin="leftMargin">
  <div class="app-card-wrapper {{ mode }}" [style.margin-left]="leftMargin">
    <a
      class="app-card"
      aria-disabled="{{ !app.isAppAvailable }}"
      disabled="{{ !app.isAppAvailable }}"
      [ngClass]="{
        available: app.isAppAvailable,
        unavailable: !app.isAppAvailable
      }"
      (click)="navigate(app)"
    >
      <div class="available-soon" *ngIf="!app.isAppAvailable">
        Unavailable in your subscription
      </div>
      <div class="new-update" *ngIf="app.hasNewUpdate">
        <span class="icon icon-star"></span>
        New Update
      </div>
      <div class="app-icon">
        <span class="icon icon-product_{{ app.name.toLowerCase() }}"></span>
      </div>
      <div class="app-name-text">
        {{ app.name }}
      </div>
      <div class="app-description-text">
        {{ app.description }}
      </div>
      <div class="open" *ngIf="app.isAppAvailable">
        <span>Open</span>
        <span class="icon icon-arrow_forward"></span>
      </div>
    </a>
  </div>
</ng-template>
