import {
  ApplicationRef,
  CUSTOM_ELEMENTS_SCHEMA,
  NO_ERRORS_SCHEMA,
  NgModule,
} from '@angular/core';
import { AuthModule, isTeamsApp } from "@vantage-platform/auth";
import { CardsService, CardsUiModule } from '@vantage-platform/cards-ui';
import { StoreRouterConnectingModule, routerReducer } from '@ngrx/router-store';

import { APP_BASE_HREF } from '@angular/common';
import { AppComponent } from './app.component';
import { AppGuard } from './shared/guards/app.guard';
import { AppInsightModule } from '@vantage-platform/app-insight';
import { AppResolver } from './shared/resolvers/app.resolver';
import { AppRoutingModule } from './app-routing.module';
import { AppsSuiteComponent } from './pages/apps-suite/apps-suite.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { CommonUiModule } from '@vantage-platform/common-ui';
import { EffectsModule } from '@ngrx/effects';
import { FocusBoardGuard } from './shared/guards/focus-board.guard';
import { HttpClientModule } from "@angular/common/http";
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { InstanceGuard } from './shared/guards/instance.guard';
import { LoginComponent } from './pages/login/login.component';
import { MsalComponent } from './pages/msal/msal.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { PerfectScrollbarModule, PerfectScrollbarConfigInterface, PERFECT_SCROLLBAR_CONFIG } from 'ngx-om-perfect-scrollbar';
import { ProductLayoutComponent } from './layouts/product-layout/product-layout.component';
import { RouterModule } from '@angular/router';
import { RoutingStateService } from '@vantage-platform/store/lib/services/routing-state.service';
import { ShareCardComponent } from './pages/share-card/share-card.component';
import { ShareCardGuard } from './shared/guards/sharecard.guard';
import { ShareToTeamsModule } from '@vantage-platform/share-to-teams';
import { SharedModule } from './shared/shared.module';
import { SiteLayoutComponent } from './layouts/site-layout/site-layout.component';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreModule } from '@ngrx/store';

import { TeamsConfigTabComponent } from './pages/teams-app/teams-config-tab/teams-config-tab.component';
import { TeamsLoginModalComponent } from './pages/teams-app/teams-login-modal/teams-login-modal.component';
import { TeamsRemoveTabComponent } from './pages/teams-app/teams-remove-tab/teams-remove-tab.component';
import { ToasterModule } from '@vantage-platform/toastr';
import { VPStoreModule } from '@vantage-platform/store';
import { environment } from '../environments/environment';

// import function to register Swiper custom elements
import { register } from 'swiper/element/bundle';
// register Swiper custom elements
register();

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
};

const isIframe = window !== window.parent && !window.opener;

@NgModule({
  declarations: [
    AppComponent,
    MsalComponent,
    LoginComponent,
    ProductLayoutComponent,
    AppsSuiteComponent,
    SiteLayoutComponent,
    TeamsLoginModalComponent,
    TeamsConfigTabComponent,
    TeamsRemoveTabComponent,
    ShareCardComponent,
  ],
  exports: [
    AppComponent,
    MsalComponent,
    LoginComponent,
    ProductLayoutComponent,
    AppsSuiteComponent,
    SiteLayoutComponent,
  ],
  imports: [
    ShareToTeamsModule,
    BrowserModule,
    RouterModule,
    AppInsightModule,
    AuthModule.forRoot(),
    BrowserAnimationsModule,
    NgxSpinnerModule,
    CommonUiModule,
    CardsUiModule,
    EffectsModule.forRoot([]),
    HttpClientModule,
    InfiniteScrollModule,
    PerfectScrollbarModule,
    SharedModule,
    BsDropdownModule.forRoot(),
    StoreModule.forRoot({ router: routerReducer }),
    StoreRouterConnectingModule.forRoot({
      stateKey: 'router', // name of reducer key
    }),
    AppRoutingModule,
    ToasterModule,
    VPStoreModule.forRoot(),
    /**
     * Store devtools instrument the store retaining past versions of state
     * and recalculating new states. This enables powerful time-travel
     * debugging.
     *
     * To use the debugger, install the Redux Devtools extension for either
     * Chrome or Firefox
     *
     * See: https://github.com/zalmoxisus/redux-devtools-extension
     */
    !environment.production ? StoreDevtoolsModule.instrument() : [],
  ],
  providers: [
    { provide: APP_BASE_HREF, useValue: '/' },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    CardsService,
    AppResolver,
    InstanceGuard,
    AppGuard,
    FocusBoardGuard,
    ShareCardGuard,
    RoutingStateService,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class AppModule {
  ngDoBootstrap(ref: ApplicationRef) {
    // Validate if app opened inside Iframe to prevent MSAL errors
    // validate if teams app - should run inside iframe
    if (isIframe && !isTeamsApp()) {
      console.log('Bootstrap: MSAL');
      ref.bootstrap(MsalComponent);
    } else {
      console.log('Bootstrap: App');
      ref.bootstrap(AppComponent);
    }
  }
}


declare global {
  interface Navigator {
    msSaveBlob: (blob: Blob, fileName: string) => boolean;
  }
}
