import { Directive, Input, HostListener, ElementRef } from '@angular/core';
import { AppResizeService } from '../utilits/app-resize.service';
@Directive({
  selector: '[vpAppResize]',
})
export class AppResizeDirective {
  @Input('leftResize') leftElement: HTMLElement;
  @Input('rightResize') rightElement: HTMLElement;
  grabber = false;
  width: number;
  defaultElementWIdth = 400; // min value for the right sidebar (ex. bot assistant)
  rightSideInPercentage: number;

  constructor(private appResizeService: AppResizeService, private el: ElementRef<HTMLElement>) {
    this.width = window.innerWidth;
    this.appResizeService.xpoint$.subscribe(val => {
      this.setWidth(window.innerWidth - val, val);
    })
  }

  @HostListener('window:resize', ['$event']) onResize(event) {
    this.width = event.target.innerWidth;
    if(this.appResizeService.enabled) {
      const rightElWidth = this.width / 100 * this.rightSideInPercentage;
      const meetsConditions = rightElWidth <= (window.innerWidth / 2) && rightElWidth >= this.defaultElementWIdth;
      if(meetsConditions) {
        this.appResizeService.xpoint = this.width - rightElWidth;
      } else {
        this.appResizeService.xpoint = this.width - this.defaultElementWIdth;
      }
    }
  }
  @HostListener('mousedown') onMouseDown() {
    this.grabber = true;
    this.el.nativeElement.classList.add('side-panel');
    document.body.style.cursor = 'e-resize';
  }

  @HostListener('window:mouseup') onMouseUp() {
    this.grabber = false;
    this.el.nativeElement.classList.remove('side-panel');
    document.body.style.cursor = 'default';
  }

  @HostListener('window:mousemove', ['$event']) onMouseMove(event: MouseEvent) {
    if (this.grabber) {
      event.preventDefault();
      const rightElWidth = (this.width || window.innerWidth) - event.clientX;
      const meetsConditions = rightElWidth <= (window.innerWidth / 2) && rightElWidth >= this.defaultElementWIdth;
      if(meetsConditions) {
        this.appResizeService.xpoint = event.clientX;
      }
    }
  }

  private setWidth(rightElWidth, leftElementWidt) {
    let leftElWidth =`${leftElementWidt - 16}px`;

    if(!this.appResizeService.enabled) {
      leftElWidth = window.innerWidth + 'px';
    } else {
      this.rightSideInPercentage = 100 - leftElementWidt / window.innerWidth * 100;
    }
    if(this.leftElement) {
      const rightElW = rightElWidth > window.innerWidth ? window.innerWidth : rightElWidth;
      this.leftElement.style.width = leftElWidth;
      this.rightElement.style.width = `${rightElW}px`;
    }
  }
}