import { ActivatedRoute, Params, Router } from '@angular/router';
import {
  AppState,
  IApp,
  IInstance,
  getApps,
  getLoaded,
  instance,
} from '@vantage-platform/store';
import { BehaviorSubject, Observable, Subject, fromEvent } from 'rxjs';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import {
  animate,
  query,
  stagger,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { filter, first, takeUntil } from 'rxjs/operators';

import { AuthService } from '@vantage-platform/auth';
import { InsightService } from '@vantage-platform/app-insight';
import { LoadingIndicatorService } from '@vantage-platform/store/lib/services/loading-indicator/loading-indicator.service';
import { MsalService } from '@azure/msal-angular';
import { Store } from '@ngrx/store';
import { TeamsContextService } from '@vantage-platform/share-to-teams';
import gitInfo from './../../../git-version.json';
import { SwiperOptions } from 'swiper/types';

const iPadProWidth = `1024px`;
const iPadProHeight = `1366px`;
const iPadProLandscapeMediaQuery = `(max-width: ${iPadProHeight}) and (max-height: ${iPadProWidth}) and (orientation: landscape)`;
const iPadProPortraitMediaQuery = `(max-width: ${iPadProWidth}) and (max-height: ${iPadProHeight}) and (orientation: portrait)`;
const touchDeviceBreakpoints = [`(hover: none)`, `(pointer: coarse)`];

@Component({
  selector: 'vp-apps-suite',
  templateUrl: './apps-suite.component.html',
  styleUrls: ['./apps-suite.component.scss'],
  animations: [
    trigger('headerAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('1000ms ease-in', style({ opacity: 1 })),
      ]),
    ]),
    trigger('listAnimation', [
      transition('* <=> *', [
        query(
          ':enter',
          [
            style({ opacity: 0, transform: 'scale(0.8)' }),
            stagger(
              '500ms',
              animate(
                '500ms ease-in',
                style({ opacity: 1, transform: 'scale(1)' })
              )
            ),
          ],
          { optional: true }
        ),
        query(':leave', animate('200ms', style({ opacity: 0 })), {
          optional: true,
        }),
      ]),
    ]),
  ],
})
export class AppsSuiteComponent implements OnInit, OnDestroy {
  version = gitInfo.hash;
  $apps: Observable<IApp[]>;

  profile: any = {};
  showAssistant: boolean;
  instance: IInstance;
  $isLoaded: Observable<boolean>;

  // Swiper
  swiperConfig: SwiperOptions = {
    spaceBetween: 30,
    navigation: true,
    centeredSlides: true,
    pagination: true,
    slidesPerView: 'auto',
    height: 400
  }

  private onDestroy$ = new Subject();
  private windowResize$ = fromEvent(window, 'resize').pipe(
    takeUntil(this.onDestroy$)
  );

  get isMobile(): boolean {
    return [Breakpoints.Handset, ...touchDeviceBreakpoints].every((mQuery) =>
      this.breakPoints.isMatched(mQuery)
    );
  }

  get isMobilLandscape(): boolean {
    return [
      Breakpoints.HandsetLandscape,
      ...touchDeviceBreakpoints,
    ].every((mQuery) => this.breakPoints.isMatched(mQuery));
  }

  get cardMode(): string {
    if (this.breakPoints.isMatched(touchDeviceBreakpoints)) {
      if (this.breakPoints.isMatched(Breakpoints.TabletLandscape)) {
        return 'full-size-condensed';
      }
      if (
        this.breakPoints.isMatched([
          Breakpoints.Tablet,
          Breakpoints.Handset,
          iPadProLandscapeMediaQuery,
          iPadProPortraitMediaQuery,
        ])
      ) {
        return 'full-size';
      }
    }
    return '';
  }

  constructor(
    public auth: AuthService,
    private store: Store<AppState>,
    private router: Router,
    private route: ActivatedRoute,
    private track: InsightService,
    public msal: MsalService,
    public ctx: TeamsContextService,
    private loadingIndicatorService: LoadingIndicatorService,
    public breakPoints: BreakpointObserver,
    public detector: ChangeDetectorRef
  ) {
    this.$apps = this.store.select(getApps);
    this.$isLoaded = this.store.select(getLoaded);
  }

  ngOnInit(): void {
    console.log(
      '%c AppsSuiteComponent Context ',
      'background: #222; color: green'
    );
    console.log(this.ctx.context);
    this.store.select(instance).subscribe((i) => (this.instance = i));
    this.store
      .select(getLoaded)
      .pipe(
        filter((_) => _),
        first()
      )
      .subscribe((_) => {
        if (_) {
          this.loadingIndicatorService.hide();
          this.track.pageView('AppSuitePage');
        }
      });

    this.windowResize$.subscribe(() => {
      this.detector.markForCheck();
    });
  }

  ngOnDestroy() {
    this.onDestroy$.next(undefined);;
  }

  navigate(app: IApp) {
    if (app.isAppAvailable) {
      this.track.event('selectApp', { appId: app.id, appName: app.name });

      if (app.isExternalApp) {
        window.open(app.url);
      } else {
        this.loadingIndicatorService.show(app);
        this.router.navigate([this.getAppLink(app)]);
      }
    }
  }

  getAppLink(app) {
    if ((this.route.params as BehaviorSubject<Params>).value.instance) {
      return `${this.router.url}${app.url}`;
    }

    return `/${this.instance.id}${app.url}`;
  }
}
