import * as appStore from './+state/app/store.reducer';

import { ModuleWithProviders, NgModule } from '@angular/core';

import { AppStoreEffects } from './+state/app/store.effects';
import { CollectionsDataService } from './services/collections-data.service';
import { CommonModule } from '@angular/common';
import { ConfigService } from './services/config.service';
import { EffectsModule } from '@ngrx/effects';
import { HelpersService } from './+state/app/helpers.service';
import { LoadingIndicatorService } from './services/loading-indicator/loading-indicator.service';
import { NgxSpinnerModule } from 'ngx-spinner';
import { StoreModule } from '@ngrx/store';
import { LocalStorageService } from './utilities/local-storage.service';

@NgModule({
  imports: [
    CommonModule,
    NgxSpinnerModule,
    StoreModule.forFeature(appStore.STORE_FEATURE_KEY, appStore.reducer),
    EffectsModule.forFeature([AppStoreEffects]),
  ],
  providers: [
    ConfigService,
    CollectionsDataService,
    LoadingIndicatorService,
    HelpersService,
    LocalStorageService
  ],
})
export class VPStoreModule {
  static forRoot(): ModuleWithProviders<VPStoreModule> {
    return {
      ngModule: VPStoreModule,
    };
  }
}
